import * as React from "react"
import PropTypes from "prop-types"
import { Link } from "gatsby"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faEnvelope } from "@fortawesome/free-solid-svg-icons"
import tw, { styled } from "twin.macro"
import SocialLinks from "./social-links"

const Footer = () => (
  <footer className="bg-gray-800 no-underline text-gray-300 h-20 flex items-center">
    <div className="container">
      <div className="flex align-items-center justify-end justify-content-between flex-column flex-sm-row">
        {/* <div>
          <p>© {new Date().getFullYear()} Brad Carson</p>
        </div> */}

        {/* <Col xs="auto">
          <Link to="/privacy">Privacy Policy</Link>
          <span className='mx-1'>·</span>
          <Link to="/terms">Terms & Conditions</Link>
        </Col> */}
        {/* <div className="flex flex-1 text-right">
          (705) 931-3894
          <br />
          brad.carson@gmail.com
        </div> */}
        <SocialLinks listClass="flex items-center justify-between w-full sm:w-auto sm:space-x-4" />
      </div>
    </div>
  </footer>
)

export default Footer
