/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import Footer from "./footer"
// import "./layout.scss"

// import "@fontsource/lato"

const Layout = ({ children, mainClass, showNav }) => {
  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)

  return (
    <>
      {showNav && (
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} />
      )}
      {/* 
      <Container fluid>
        <Row>
          <Col>
          <p>Hello....</p>
          </Col>
        </Row>
      </Container> */}
      <main className={mainClass}>{children}</main>
      <Footer />
    </>
  )
}

Layout.defaultProps = {
  mainClass: ``,
  showNav: true,
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  mainClass: PropTypes.string,
  showNav: PropTypes.bool,
}

export default Layout
